@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
	background: $color-white;
	border: rem(15) solid $color-purple;
	font-family: 'Homer Simpson', sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 1;
	text-transform: uppercase;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	align-items: flex-start;
	color: $color-black;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	min-height: calc(100vh - 1.875rem);
	position: relative;
}


.bgvideo{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    z-index: 0;
}

header, main, footer{
	position: relative; 
}

a {
	color: $color-black;

	@include hover-focus {
		color: $color-black;
	}
}

header {
	flex: 0 0 auto;
	padding: rem(35) 0;
	text-align: center;
	width: 100%;

	.container {
		max-width: 800px;

		> nav {
			align-items: center;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;

			@include tablet-down {
				flex-wrap: wrap;
				justify-content: center;
			}

			> ul {
				list-style: none;
				margin: 0;
				padding: 0;

				> li {
					display: inline-block;
					font-size: rem(25);
					vertical-align: middle;

					+ li {
						margin-left: rem(50);
					}

					> a {
						opacity: 1;

						@include hover-focus {
							opacity: 0.8;
						}
					}
				}

				&:first-child {

					@include tablet-down {
						order: 2;
					}

					> li {
						&:first-child {
							> a {
								color: $color-purple;
							}
						}

						&:last-child {
							> a {
								color: $color-salmon;
							}
						}
					}
				}

				&:last-child {
					@include tablet-down {
						margin-left: rem(50);
						order: 3;
					}

					> li {
						&:first-child {
							> a {
								color: $color-teal;
							}
						}

						&:last-child {
							> a {
								color: $color-purple-2;
							}
						}
					}
				}
			}

			> h1 {
				margin: 0;

				@include tablet-down {
					flex: 0 0 100%;
					order: 1;
				}

				> img {
					width: rem(369);

					@include tablet-down {
						margin: 0 auto;
					}
				}
			}
		}

		> .socials {
			list-style: none;
			margin: rem(15) 0 0 0;
			padding: 0;

			> li {
				display: inline-block;
				font-size: rem(16);
				vertical-align: middle;

				+ li {
					margin-left: rem(10);
				}

				> a {
					color: $color-white;

					@include hover-focus {
						color: $color-teal;
					}
				}
			}
		}
	}
}

main {
	flex: 1 1 auto;
	width: 100%;

	#tour {
		h2 {
			color: $color-purple;
			font-size: rem(35);
			font-weight: 500;
			margin: 0 0 rem(25) 0;
			text-align: center;
		}

		#events {
			margin: 0 auto;
			max-width: 450px;

			> .event {
				align-items: center;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;

				@include phone-down {
					display: block;
					padding-right: rem(100);
					position: relative;
				}

				> .event-date {
					color: $color-purple;
					flex: 0 0 rem(55);
					font-size: rem(20);
				}

				> .event-venue-location {
					flex: 1 1 auto;
					font-sizee: rem(14);
					line-height: line-height(18, 14);
					padding: 0 rem(50);
					text-transform: none;

					@include tablet-down {
						padding: 0;
					}
				}

				> .event-links {
					flex: 0 0 auto;

					@include phone-down {
						position: absolute;
						right: 0;
						top: 0;
					}

					> a {
						background: $color-teal;
						color: $color-white;
						display: inline-block;
						font-size: rem(20);
						padding: rem(15) rem(40);
						text-align: center;
						text-transform: none;
						vertical-align: middle;

						@include hover-focus {
							background: $color-purple;
							color: $color-white;
						}
					}
				}

				+ .event {
					margin-top: rem(35);
				}
			}
		}
	}
}

footer {
	flex: 0 0 auto;
	padding: rem(35) 0;
	width: 100%;

	.container {
		align-items: flex-end;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		max-width: 100%;
		padding: 0 rem(50);

		@include tablet-down {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
		}

		.album {
			align-items: center;
			display: flex;
			flex: 0 1 calc(100% - #{rem(250)});
			flex-direction: row;
			flex-wrap: nowrap;

			@include tablet-down {
				flex: 0 0 auto;
				margin: 0 auto;
				max-width: rem(300);
				order: 1;
				width: 100%;
			}

			> a {
				display: inline-block;
				vertical-align: middle;

				> img {
					width: rem(61);
				}
			}

			> div {
				color: $color-purple;
				padding-left: rem(13);

				> span {
					display: block;
					font-size: rem(35);
					text-transform: lowercase;
				}

				> a {
					color: $color-purple;
					font-size: rem(20);
					text-decoration: underline;

					@include hover-focus {
						color: $color-purple;
						text-decoration: none;
					}
				}
			}
		}

		.copyright {
			color: $color-salmon;
			flex: 0 1 auto;
			font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
			font-weight: normal;
			max-width: rem(500);
			text-align: center;

			@include tablet-down {
				flex: 0 0 auto;
				order: 3;
				width: 100%;
			}

			> a {
				display: inline-block;
				line-height: 1;
				vertical-align: middle;
				width: rem(42);

				> svg {
					fill: $color-salmon;
					fill-rule: evenodd;
					height: auto;
					transition: color .25s linear;
					width: 100%;
				}

				@include hover-focus {
					> svg {
						fill: $color-teal;
					}
				}
			}

			> p {
				font-size: rem(8);
				line-height: line-height(12, 8);
				margin: 0;

				> a {
					color: $color-salmon;

					@include hover-focus {
						color: $color-salmon;
					}
				}
			}
		}

		.newsletter {
			flex: 0 1 calc(100% - #{rem(250)});

			@include tablet-down {
				flex: 0 0 auto;
				margin: rem(20) 0;
				order: 2;
				width: 100%;
			}

			> .form-wrap {
				margin: 0 0 0 auto;
				max-width: rem(300);

				@include tablet-down {
					margin: 0 auto;
				}

				> span {
					color: $color-purple;
					display: block;
					font-size: rem(30);
					margin: 0 0 rem(20) 0;

					@include tablet-down {
						text-align: center;
					}
				}

				> form {
					align-items: flex-end;
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;

					> input {
						border: 0;
						background: transparent;
						border-bottom: 1px solid $color-purple;
						color: $color-white;
						display: block;
						flex: 1 1 auto;
						font-size: rem(14);
						font-weight: 500;
						padding: 0 0 rem(5) 0;
						width: 100%;

						@include placeholder {
							color: $color-white;
						}

						&:focus {
							border-color: $color-salmon;
							outline: 0;
						}
					}

					> button {
						background: $color-teal;
						border: 0;
						color: $color-white;
						display: inline-block;
						flex: 0 0 auto;
						font-size: rem(20);
						line-height: 1;
						margin-left: rem(20);
						padding: rem(10) rem(25);
						text-align: center;
						text-transform: none;
						vertical-align: middle;

						@include hover-focus {
							background: $color-purple;
							color: $color-white;
						}
					}
				}
			}
		}
	}
}
