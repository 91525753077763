@font-face {
	font-family: 'Homer Simpson';
	src: url('../font/HomerSimpson.woff2') format('woff2'),
	url('../font/HomerSimpson.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #000;

$color-purple: #D1C2E3;
$color-purple-2: #DEBDC6;
$color-teal: #C1E4EA;
$color-salmon: #F9B8B4;
